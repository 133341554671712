<!--账户检索-->
<template>
  <div class="warp">
    <el-card class="warp-content">
      <!-- 按钮操作区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24" style="text-align: right">
            <el-input
              placeholder="请输入用户名"
              style="width: 202px; margin-right: 4px; margin-top: 6px"
              prefix-icon="search"
              size="small"
              @input="searchInput"
              v-model="searchValue"
            >
            </el-input>
            <el-input
              placeholder="请输入手机号"
              style="width: 202px; margin-right: 4px; margin-top: 6px"
              prefix-icon="search"
              size="small"
              @input="searchInput"
              v-model="searchValue"
            >
            </el-input>
            <el-button type="primary" size="small" @click="searchClick"
              >搜索
            </el-button>
          </el-col>
        </el-row>
      </div>
      <!-- 表格区域 -->
      <div class="page-container">
        <el-table
          ref="tableRef"
          :data="tableData"
          style="width: 100%"
          max-height="630px"
          :header-cell-style="{ background: '#F2F4F8' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" align="center" width="80" />
          <el-table-column
            v-for="(item, index) in tableColumnData"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
          :total="total"
          @pageChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        ></page-nation>
      </div>
    </el-card>
  </div>
</template>

  <script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue-demi";
import pageNation from "@/components/base/pageNation.vue";
// import { getAllDataApi } from "@/api/journal/equipmentStatus/equipmentStatus.js";
import { requestApi } from "@/utils/requestApi";
import { debounces } from "@/common/utils";
import { queryAllInfoByOrgId, deriveXls3 } from "@/api/journal";
export default {
  name: "AccountSearch",
  components: { pageNation },
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      searchValue: null, //搜索内容（用户名）
      searchContent: null, //搜索日志内容
      tableData: [{ userName: "45" }], // 表格数据
      total: 0,
      pageSize: 10,
      currentPage: 1,
      userInfo: null,
      selection: [],
      disabled: true,
      formData: {
        alarTime: [], //起始时间
      },
      tableColumnData: [
        {
          prop: "userName",
          label: "账户类型",
        },
        {
          prop: "userName",
          label: "账户号",
        },
        {
          prop: "userName",
          label: "卡号",
        },
        {
          prop: "userName",
          label: "账户余额",
        },
        {
          prop: "userName",
          label: "赠送金额",
        },
        {
          prop: "userName",
          label: "到期时间",
        },
        {
          prop: "userName",
          label: "绑定充电站",
        },
        {
          prop: "userName",
          label: "所属商户",
        },
      ],
    });
    onMounted(() => {
      proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    });
    // 节流搜索按钮
    const searchInput = debounces(() => {}, 500);
    const methods = {
      editClick(row) {
        console.log(row);
      },
    };
    return {
      ...toRefs(data),
      searchInput,
      ...methods,
    };
  },
};
</script>
  <style lang="less" scoped>
.warp {
  height: 100%;
  .warp-content {
    height: 100%;
  }
}
.page-select {
  width: 100%;
  margin: 0 auto;
  .formClass {
    display: inline-block;
    margin-right: 4px;
  }
}
.page-container {
  width: 100%;
}
.page-foot {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .my-btn {
    height: 28px;
  }
}
</style>
